/* tslint:disable */
/* eslint-disable */
/**
 * Tanganica API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const EnumsAdminPermission = {
    GetAccounting: 'GetAccounting',
    SetAccounting: 'SetAccounting',
    ViewAccountingTab: 'ViewAccountingTab',
    GetKpiReport: 'GetKpiReport',
    GetKpiReportActivatedEshops: 'GetKpiReportActivatedEshops',
    GetKpiReportRegisteredEshops: 'GetKpiReportRegisteredEshops',
    GetExport: 'GetExport',
    RegisterNewAdmin: 'RegisterNewAdmin',
    GetTopMenuEshopsCredit: 'GetTopMenuEshopsCredit',
    GetTopMenuActiveEshopsCount: 'GetTopMenuActiveEshopsCount',
    SetEshopDetail: 'SetEshopDetail',
    SetGoogleCampaigns: 'SetGoogleCampaigns',
    SetProducts: 'SetProducts',
    SetUserDetail: 'SetUserDetail',
    SetInternalUserPermission: 'SetInternalUserPermission',
    SetInternalUserRole: 'SetInternalUserRole'
} as const;

export type EnumsAdminPermission = typeof EnumsAdminPermission[keyof typeof EnumsAdminPermission];



