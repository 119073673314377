import { createWebHistory, createRouter } from 'vue-router';
import { ADMIN_ROUTES } from '@/enums/AdminRoutesEnum';
import { PUBLIC_ROUTES } from '@/enums/PublicRoutesEnum';
import routes from '@/router/routes';
import { useUserStore } from '@/stores/user';

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const userStore = useUserStore();

  // Redirect logic
  if (to.meta.client && !to.meta.guest && !userStore.getIsLoggedIn) {
    next({
      name: PUBLIC_ROUTES.LOGIN_PAGE,
    });
  } else if (!to.meta.client && to.meta.guest && userStore.getIsLoggedIn) {
    next({
      name: ADMIN_ROUTES.ESHOPS,
    });
  } else {
    // Check permissions
    if (to.meta.permissions) {
      const permissions = userStore.permissions;

      if (to.meta.permissions.condition === 'AND') {
        if (to.meta.permissions.list.every((permission) => permissions.includes(permission))) {
          next();
        } else {
          next({
            name: ADMIN_ROUTES.ESHOPS,
          });
        }
      } else {
        if (to.meta.permissions.list.some((permission) => permissions.includes(permission))) {
          next();
        } else {
          next({
            name: ADMIN_ROUTES.ESHOPS,
          });
        }
      }
    } else {
      next();
    }
  }
});

export default router;
