import PrimeVue from 'primevue/config';
import { usePassThrough } from 'primevue/passthrough';
import Tailwind from 'primevue/passthrough/tailwind';
import { button } from '@/plugins/primeVue/button';
import { calendar } from '@/plugins/primeVue/calendar';
import { datatable } from '@/plugins/primeVue/datatable';
import { dialog } from '@/plugins/primeVue/dialog';
import { dropdown } from '@/plugins/primeVue/dropdown';
import { inputtext } from '@/plugins/primeVue/inputtext';
import { menu } from '@/plugins/primeVue/menu';
import { multiselect } from '@/plugins/primeVue/multiselect';
import { paginator } from '@/plugins/primeVue/paginator';
import { password } from '@/plugins/primeVue/password';
import { tag } from '@/plugins/primeVue/tag';
import { tristatecheckbox } from '@/plugins/primeVue/tristatecheckbox';

const CustomTailwind = usePassThrough(
  Tailwind,
  {
    dialog,
    paginator,
    datatable,
    menu,
    multiselect,
    tag,
    calendar,
    inputtext,
    button,
    tristatecheckbox,
    dropdown,
    password,
  },
  {
    mergeSections: false,
    mergeProps: false,
  }
);

const primeVuePlugin = {
  install: (app) => {
    app.use(PrimeVue, {
      unstyled: true,
      pt: CustomTailwind,
    });
  },
};

export default primeVuePlugin;
