<template>
  <Toast />
  <Loading />
  <component :is="layout.component">
    <router-view />
  </component>
</template>
<script setup lang="ts">
import Toast from 'primevue/toast';
import { computed, onMounted, markRaw } from 'vue';
import { useRoute } from 'vue-router';
import LanguagesCodebook from '@/codebooks/LanguagesCodebook';
import { LayoutEnum } from '@/enums/LayoutEnum';
import AdminLayout from '@/layouts/AdminLayout.vue';
import Loading from '@/modules/global/Loading.vue';
import { useUserStore } from '@/stores/user';
import AuthLayout from './layouts/AuthLayout.vue';

interface ILayoutComponent {
  props?: any;
  component: any;
}

const route = useRoute();
const user = useUserStore();

const url = new URL(window.location.href);
const PARAM_LANG = 'lang';

const defaultLanguage = 'cs';

onMounted(async () => {
  initLanguage();
});

// Set layout
// TODO - find out why props are not passed to the layout
const layout = computed((): ILayoutComponent => {
  const layoutName = route.meta.layout;

  switch (layoutName) {
    case LayoutEnum.ADMIN:
      return {
        props: null,
        component: markRaw(AdminLayout),
      };
    case LayoutEnum.AUTH:
      return {
        props: null,
        component: markRaw(AuthLayout),
      };
    default:
      return {
        props: null,
        component: markRaw(AdminLayout),
      };
  }
});

// Init language
const initLanguage = () => {
  // Check if language is set in url
  if (url.searchParams.has(PARAM_LANG)) {
    const URLLang = url.searchParams.get(PARAM_LANG);
    const language = LanguagesCodebook.find((c) => c.code === URLLang);
    if (language) {
      user.setLanguage(language.code);
      return;
    }
    user.setLanguage(defaultLanguage); // default language
    return;
  }

  // Check if language is set in local storage
  if (user.languageCode) {
    user.setLanguage(user.languageCode);
    return;
  }

  // Check if language is set in browser
  const browserPreferredLanguages = navigator.languages;
  let matchedLanguage = defaultLanguage; // default language

  for (let i = 0; i < browserPreferredLanguages.length; i++) {
    const languageCode = browserPreferredLanguages[i].split('-')[0];
    const isLanguageSupported = LanguagesCodebook.find((c) => c.code === languageCode);
    if (isLanguageSupported) {
      matchedLanguage = languageCode;
      break;
    }
  }
  user.setLanguage(matchedLanguage);
};
</script>
