import '@/assets/main.css';
import InputText from 'primevue/inputtext';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import { createApp } from 'vue';
import i18n from '@/plugins/i18n';
import pinia from '@/plugins/pinia';
import primeVuePlugin from '@/plugins/primeVue';
import { useUserStore } from '@/stores/user';
import App from './App.vue';
import router from './router';

async function init() {
  const app = createApp(App);
  app.config.globalProperties.$filePath = import.meta.env.VITE_FILE_SYSTEM_URL;

  app.use(i18n);
  app.use(pinia);
  app.use(primeVuePlugin);
  app.use(ToastService);

  app.component('InputText', InputText);
  app.directive('tooltip', Tooltip);

  const userStore = useUserStore();
  await userStore.getPermissions();
  app.use(router);

  app.mount('#app');
}

init();
