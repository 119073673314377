import { RouteRecordRaw } from 'vue-router';
import { ADMIN_ROUTES } from '@/enums/AdminRoutesEnum';
import { LayoutEnum } from '@/enums/LayoutEnum';
import { PUBLIC_ROUTES } from '@/enums/PublicRoutesEnum';
import {EnumsAdminPermission} from "../../generated/api";

const routes: Array<RouteRecordRaw> = [
  // Admin routes
  {
    path: '',
    name: PUBLIC_ROUTES.LOGIN_PAGE,
    meta: {
      layout: LayoutEnum.AUTH,
      guest: true,
      client: false,
    },
    component: () => import('@/modules/auth/pages/Login.vue'),
  },
  {
    path: '/client',
    meta: {
      layout: LayoutEnum.ADMIN,
      guest: false,
      client: true,
    },
    children: [
      // Eshops
      {
        path: 'eshops',
        name: ADMIN_ROUTES.ESHOPS,
        meta: {
          guest: false,
          client: true,
        },
        component: () => import('@/modules/eshops/pages/Eshops.vue'),
      },
      // Eshop detail
      {
        path: 'eshop/:id',
        name: ADMIN_ROUTES.ESHOP_DETAIL,
        meta: {
          guest: false,
          client: true,
        },
        component: () => import('@/modules/eshops/pages/EshopDetail.vue'),
      },
      // Users
      {
        path: 'users',
        name: ADMIN_ROUTES.USERS,
        meta: {
          guest: false,
          client: true,
        },
        component: () => import('@/modules/users/pages/Users.vue'),
      },
      // User detail
      {
        path: 'user/:id',
        name: ADMIN_ROUTES.USER_DETAIL,
        meta: {
          guest: false,
          client: true,
        },
        component: () => import('@/modules/users/pages/UserDetail.vue'),
      },
      // accouting
      {
        path: 'accounting',
        name: ADMIN_ROUTES.ACCOUNTING_PAGE,
        meta: {
          guest: false,
          client: true,
          permissions: {
            list: [EnumsAdminPermission.ViewAccountingTab, EnumsAdminPermission.GetAccounting],
            condition: 'and',
          }
        },
        component: () => import('@/modules/accouting/pages/Accounting.vue'),
      },
      // Kpi reports
      {
        path: 'kpi-reports',
        name: ADMIN_ROUTES.KPI_REPORTS,
        meta: {
          guest: false,
          client: true,
          permissions: {
            list: [EnumsAdminPermission.GetKpiReport, EnumsAdminPermission.GetKpiReportActivatedEshops, EnumsAdminPermission.GetKpiReportRegisteredEshops],
            condition: 'or',
          },
        },
        component: () => import('@/modules/kpiReports/pages/KpiReports.vue'),
      },
      // Export
      {
        path: 'export',
        name: ADMIN_ROUTES.EXPORT_PAGE,
        meta: {
          guest: false,
          client: true,
          permissions: {
            list: [EnumsAdminPermission.GetExport],
            condition: 'or',
          }
        },
        component: () => import('@/modules/export/pages/Export.vue'),
      },
      // Register new user
      {
        path: 'register-new-user',
        name: ADMIN_ROUTES.REGISTER_PAGE,
        meta: {
          guest: false,
          client: true,
          permissions: {
            list: [EnumsAdminPermission.RegisterNewAdmin],
            condition: 'or',
          }
        },
        component: () => import('@/modules/auth/pages/Register.vue'),
      },
      // Feed
      {
        path: 'feed',
        name: ADMIN_ROUTES.FEED_PAGE,
        meta: {
          guest: false,
          client: true,
        },
        component: () => import('@/modules/feed/pages/Feed.vue'),
      },
      // Uncreated campaigns
      {
        path: 'uncreated-campaigns',
        name: ADMIN_ROUTES.UNCREATED_CAMPAIGNS,
        meta: {
          guest: false,
          client: true,
        },
        component: () => import('@/modules/uncreatedCampaigns/pages/UncreatedCampaigns.vue'),
      },
      // Products
      {
        path: 'products',
        name: ADMIN_ROUTES.PRODUCTS,
        meta: {
          guest: false,
          client: true,
        },
        component: () => import('@/modules/products/pages/Products.vue'),
      },
      // Logs
      {
        path: 'logs',
        name: ADMIN_ROUTES.LOGS,
        meta: {
          guest: false,
          client: true,
        },
        component: () => import('@/modules/logs/pages/Logs.vue'),
        redirect() {
          return {
            name: ADMIN_ROUTES.APP_LOGS,
          };
        },
        children: [
          {
            path: 'application',
            name: ADMIN_ROUTES.APP_LOGS,
            meta: {
              guest: false,
              client: true,
            },
            component: () => import('@/modules/logs/pages/ApplicationLogs.vue'),
          },
          {
            path: 'email',
            name: ADMIN_ROUTES.EMAIL_LOGS,
            meta: {
              guest: false,
              client: true,
            },
            component: () => import('@/modules/logs/pages/EmailLogs.vue'),
          },
        ],
      },
      // Subscription
      {
        path: 'admins',
        name: ADMIN_ROUTES.ADMINS,
        meta: {
          guest: false,
          client: true,
        },
        component: () => import('@/modules/admins/pages/Index.vue'),
      },
      // Logout
      {
        path: 'logout',
        name: ADMIN_ROUTES.LOGOUT_PAGE,
        meta: {
          guest: false,
          client: true,
        },
        component: () => import('@/modules/auth/pages/Logout.vue'),
      },
    ],
  },
  // 404
  {
    path: '/:pathMatch(.*)*',
    name: ADMIN_ROUTES.NOT_FOUND,
    redirect: {
      name: ADMIN_ROUTES.ESHOPS,
    },
  },
];

export default routes;
