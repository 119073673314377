export const inputtext = {
  root: ({ props, context }) => ({
    class: [
      'm-0',
      'font-sans text-gray-600 bg-white transition-colors duration-200 appearance-none rounded-lg shadow-xl w-full',
      {
        'hover:border-blue-500 focus:outline-none focus:outline-offset-0 focus:border': !context.disabled,
        'opacity-60 select-none pointer-events-none cursor-default': context.disabled,
      },
      {
        'text-lg px-4 py-4': props.size == 'large',
        'text-xs px-2 py-2': props.size == 'small',
        'p-3 text-base': props.size == null,
      },
    ],
  }),
};
