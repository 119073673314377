import { defineStore } from 'pinia';
import { RequestTypeEnum } from '@/enums/RequestTypeEnum';
import { api } from '@/services/api';

interface State {
  accessToken: string | null;
  refreshToken: string | null;
}

export const useAuthStore = defineStore('auth', {
  state: (): State => ({
    accessToken: null,
    refreshToken: null,
  }),
  getters: {
    getAccessToken: (state) => state.accessToken,
    getRefreshToken: (state) => state.refreshToken,
  },
  actions: {
    setAccessToken(accessToken: string) {
      this.accessToken = accessToken;
    },
    setAuthTokens(accessToken: string, refreshToken: string) {
      this.accessToken = accessToken;
      this.refreshToken = refreshToken;
    },
    async refreshUserAccessToken() {
      try {
        const { data: adminAuthGetUserAccessTokenResponse } = await api.adminAuthGetUserAccessToken({
          headers: {
            Authorization: `Bearer ${this.refreshToken}`,
          },
          metadata: {
            requestType: RequestTypeEnum.REFRESH_TOKEN,
          },
        });

        if (adminAuthGetUserAccessTokenResponse?.accessToken) {
          this.accessToken = adminAuthGetUserAccessTokenResponse.accessToken;
          return adminAuthGetUserAccessTokenResponse.accessToken;
        } else {
          return '';
        }
      } catch (error) {
        return '';
      }
    },
    destroySession() {
      this.accessToken = null;
      this.refreshToken = null;
    },
  },
  persist: {
    paths: ['accessToken', 'refreshToken'],
  },
});
