<template>
  <div>
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" class="relative z-50 lg:hidden" @close="sidebarOpen = false">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-900/80" />
        </TransitionChild>

        <div class="fixed inset-0 flex">
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
              <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
                <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                  <button type="button" class="-m-2.5 p-2.5" @click="sidebarOpen = false">
                    <span class="sr-only">Close sidebar</span>
                    <span class="material-symbols-outlined text-white">close</span>
                  </button>
                </div>
              </TransitionChild>
              <!-- Sidebar component, swap this element with another sidebar if you like -->
              <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-primary pb-4 md:px-6">
                <div class="flex h-16 shrink-0 items-center">
                  <img class="h-8 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=white" alt="Your Company" />
                </div>
                <nav class="flex flex-1 flex-col">
                  <ul role="list" class="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" class="-mx-2 space-y-1">
                        <li
                          v-for="item in items.filter((i) => {
                            return i.show !== false;
                          })"
                          :key="item.label"
                        >
                          <router-link v-slot="{ isActive }" :to="{ name: item.route }">
                            <span :class="[isActive ? 'bg-white text-black' : 'text-white hover:bg-white hover:text-black', 'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6']">
                              <span :class="[isActive ? 'text-black' : 'text-white group-hover:text-black', 'material-symbols-outlined h-6 w-6 shrink-0']" aria-hidden="true">
                                {{ item.icon }}
                              </span>
                              {{ item.label }}
                            </span>
                          </router-link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-52 lg:flex-col">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-primary px-6 pb-4">
        <div class="flex h-16 shrink-0 items-center">
          <img class="h-auto w-auto" src="/images/logo.png" alt="Your Company" />
        </div>
        <nav class="flex flex-1 flex-col">
          <ul role="list" class="flex flex-1 flex-col gap-y-7">
            <li>
              <ul role="list" class="-mx-2 space-y-1">
                <li
                  v-for="item in items.filter((i) => {
                    return i.show !== false;
                  })"
                  :key="item.label"
                >
                  <router-link v-slot="{ isActive }" :to="{ name: item.route }">
                    <div :class="[isActive ? 'bg-white text-black' : 'text-white hover:bg-white hover:text-black', 'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6']">
                      <span :class="[isActive ? 'text-black' : 'text-white group-hover:text-black', 'material-symbols-outlined h-6 w-6 shrink-0']">
                        {{ item.icon }}
                      </span>
                      {{ item.label }}
                    </div>
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <div class="lg:pl-52">
      <div class="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-primary px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
        <button type="button" class="-m-2.5 p-2.5 text-gray-700 lg:hidden" @click="sidebarOpen = true">
          <span class="sr-only">Open sidebar</span>
          <span class="material-symbols-outlined text-white">menu</span>
        </button>
        <div v-if="dataLoaded" class="min-w-max text-xs text-white">
          <span class="mr-2" v-if="can(EnumsAdminPermission.GetTopMenuEshopsCredit)">
            Plus credit <LocalizedValue :value="eshopCredit.czech"></LocalizedValue><span class="mx-2">+</span>
            <LocalizedValue :value="eshopCredit.foreign"></LocalizedValue>
          </span>
          <span v-if="can(EnumsAdminPermission.GetTopMenuActiveEshopsCount)">
            <span class="ml-2 hidden md:inline">Czech Eshop Count: {{ eshopCount.czech }}</span>
            <span class="ml-2 hidden md:inline">Foreign Eshops Count: {{ eshopCount.foreign }}</span>
            <span class="ml-2">Total Eshops Count: {{ eshopCount.total }}</span>
          </span>
        </div>

        <div class="flex w-full cursor-pointer justify-end">
          <router-link :to="{ name: ADMIN_ROUTES.LOGOUT_PAGE }">
            <span class="material-symbols-outlined text-3xl text-white">logout</span>
          </router-link>
        </div>
      </div>

      <main class="py-10">
        <div class="px-2 sm:px-6 lg:px-8">
          <router-view></router-view>
        </div>
      </main>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { ref, onMounted, reactive, computed } from 'vue';
import { ADMIN_ROUTES } from '@/enums/AdminRoutesEnum';
import LocalizedValue from '@/modules/global/LocalizedValue.vue';
import { api } from '@/services/api';
import { can } from '@/utils/can';
import { EnumsAdminPermission, GlobalModelsMoneyResponse } from '../../generated/api';

const dataLoaded = ref(false);

const eshopCount = reactive({
  czech: 0,
  foreign: 0,
  total: 0,
});

const eshopCredit = reactive<{
  czech: GlobalModelsMoneyResponse | null;
  foreign: GlobalModelsMoneyResponse | null;
}>({
  czech: null,
  foreign: null,
});

onMounted(async () => {
  if (can(EnumsAdminPermission.GetTopMenuEshopsCredit)) {
    const { data } = await api.adminTopMenuGetEshopsCredit();
    eshopCredit.czech = data.czechEshopsCredit ?? null;
    eshopCredit.foreign = data.foreignEshopsCredit ?? null;
  }

  if (can(EnumsAdminPermission.GetTopMenuActiveEshopsCount)) {
    const { data } = await api.adminTopMenuGetActiveEshopsCount();
    eshopCount.czech = data.czechEshopsCount ?? 0;
    eshopCount.foreign = data.foreignEshopsCount ?? 0;
    eshopCount.total = data.totalEshopsCount ?? 0;
  }

  dataLoaded.value = true;
});

const items = computed(() => {
  return [
    { label: 'E-shops', icon: 'store', route: ADMIN_ROUTES.ESHOPS, index: 0, show: true },
    { label: 'Users', icon: 'group', route: ADMIN_ROUTES.USERS, index: 1, show: true },
    {
      label: 'Accounting',
      icon: 'order_play',
      route: ADMIN_ROUTES.ACCOUNTING_PAGE,
      index: 2,
      show: can(EnumsAdminPermission.ViewAccountingTab) && can(EnumsAdminPermission.GetAccounting),
    },
    {
      label: 'KPI Report',
      icon: 'query_stats',
      route: ADMIN_ROUTES.KPI_REPORTS,
      index: 3,
      show: can(EnumsAdminPermission.GetKpiReport) || can(EnumsAdminPermission.GetKpiReportActivatedEshops) || can(EnumsAdminPermission.GetKpiReportRegisteredEshops),
    },
    {
      label: 'Export',
      icon: 'export_notes',
      route: ADMIN_ROUTES.EXPORT_PAGE,
      index: 4,
      show: can(EnumsAdminPermission.GetExport),
    },
    { label: 'Feed', icon: 'feed', route: ADMIN_ROUTES.FEED_PAGE, index: 5, show: true },
    { label: 'Campaigns', icon: 'campaign', route: ADMIN_ROUTES.UNCREATED_CAMPAIGNS, index: 6, show: true },
    { label: 'Products', icon: 'inventory_2', route: ADMIN_ROUTES.PRODUCTS, index: 7, show: true },
    {
      label: 'Registration',
      icon: 'person_add',
      route: ADMIN_ROUTES.REGISTER_PAGE,
      index: 8,
      show: can(EnumsAdminPermission.RegisterNewAdmin),
    },
    { label: 'Logs', icon: 'report', route: ADMIN_ROUTES.LOGS, index: 9, show: true },
    { label: 'Admins', icon: 'subscriptions', route: ADMIN_ROUTES.ADMINS, index: 10, show: true },
  ];
});

const sidebarOpen = ref(false);
</script>
